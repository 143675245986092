<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Pasien Ultah</b> {{ clinic_name }}</div>
      <!-- <b-button
        squared
        variant="success"
        @click="$router.push('/patient/add')"
        v-if="manipulateBtn == true"
        >Tambah</b-button
      > -->
      <!-- <b-button
        squared
        variant="outline-primary"
        class="mx-1"
        @click="$router.push('/patient/import')"
      >
        <i class="fas fa-file-excel pr-1"></i>
        Unggah Excel</b-button
      > -->
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-end mt-n3">
              <div class="col-md-9">
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('days')"
                  v-bind:class="{
                    'btn-info': btnActive.days,
                    'btn-outline-info': btnActive.days == false,
                  }">
                  Hari Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('month')"
                  v-bind:class="{
                    'btn-info': btnActive.month,
                    'btn-outline-info': btnActive.month == false,
                  }">
                  Bulan Ini
                </button>
                <!-- <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('lastMonth')"
                  v-bind:class="{
                    'btn-info': btnActive.lastMonth,
                    'btn-outline-info': btnActive.lastMonth == false,
                  }"
                >
                  Bulan Kemarin
                </button> -->
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('nextMonth')"
                  v-bind:class="{
                    'btn-info': btnActive.nextMonth,
                    'btn-outline-info': btnActive.nextMonth == false,
                  }">
                  Bulan Depan
                </button>
                <!-- <button
                  class="btn mx-1 btn-timeseries"
                  v-bind:class="{
                    'btn-info': btnActive.period,
                    'btn-outline-info': btnActive.period == false,
                  }"
                  @click="btnPeriodeOnClick"
                >
                  Periode
                </button> -->
              </div>
              <div class="col-md-3 col-12">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Pasien Ultah"
                    @keyup="filterByName"></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- timeseries filter -->
            <div class="row mt-3">
              <div class="col-md-9" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Awal"
                      autocomplete="off"
                      readonly></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Akhir"
                      autocomplete="off"
                      readonly></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button squared @click="pagination" variant="success">Cari</b-button>
                      <b-button squared @click="resetFilter" variant="danger">Reset</b-button>
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Awal"
                          autocomplete="off"
                          readonly></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"></b-form-datepicker>
                        </b-input-group-append>
                        <template #append>
                          <b-button
                            squared
                            @click="pagination"
                            variant="success">Cari</b-button>
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger">Reset</b-button>
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Akhir"
                          autocomplete="off"
                          readonly></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>

            <div class="row justify-content-end">
              <button class="btn mx-1 btn-warning" @click="settingWa">
                <i class="fas fa-cog"></i> Pengaturan
              </button>
            </div>

            <b-table striped hover :items="items" :fields="fields">
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }" />
              </template>
              <template #cell(status)="data">
                <b-badge pill variant="danger" v-if="data.item.is_receipt_message_birthday == null">Belum Terkirim</b-badge>
                <b-badge pill variant="success" v-if="data.item.is_receipt_message_birthday != null">Terkirim</b-badge>
              </template>

              <template #cell(actions)="data">
                <b-button v-if="data.item.is_receipt_message_birthday == null"
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Kirim Whatsapp"
                  @click="showModalPreview(data.item)">
                  <i class="fab fa-whatsapp px-0"></i>
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"></b-pagination>
          </template>
        </Card>
      </div>
    </div>

    <!-- Modal WA Setting -->
    <b-modal id="modal-wa-settings" hide-footer>
      <template #modal-title> Pengaturan Pesan Ulang Tahun </template>

      <b-form @submit.stop.prevent="editWaSetting($event)">
        <!-- Input Description -->
        <b-form-group id="input-group-wa_birthday_message">
          <label for="input-wa_birthday_message">Ucapan Selamat : </label>
          <b-form-textarea
            id="input-wa_birthday_message"
            v-model="formWaSetting.wa_birthday_message"
            placeholder="Ucapan Selamat"
            rows="4"
            max-rows="6"></b-form-textarea>
          <small class="text-danger">{{
            errorWaSetting.wa_birthday_message
          }}</small>
        </b-form-group>

        <!-- <b-form-group id="input-group-wa_birthday_message">
          <label for="input-wa_birthday_message">Kirim Pesan Otomatis :</label>
          <div class="row">
            <div class="col">
              <b-form-checkbox
                switch
                class="mr-n2"
                v-model="formWaSetting.is_wa_auto_send"
              >
                <span>{{
                  formWaSetting.is_wa_auto_send ? "Aktif" : "Tidak Aktif"
                }}</span>
              </b-form-checkbox>
            </div>
            <span>{{ errorWaSetting.is_wa_auto_send }}</span>
          </div>
        </b-form-group> -->
        <div class="d-flex justify-content-end">
          <b-button variant="primary" type="submit" class="mr-1">
            Terapkan
          </b-button>
          <b-button
            type="button"
            variant="secondary"
            @click="$bvModal.hide('modal-wa-settings')">
            Tutup
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Modal Preview -->
    <b-modal id="modal-preview-message" hide-footer>
      <template #modal-title> Kirim Pesan Ulang Tahun </template>

      <b-form @submit.stop.prevent="sendMessage($event)">
        <!-- Input Description -->
        <b-form-group id="input-group-wa_birthday_message">
          <label for="input-wa_birthday_message">Ucapan Selamat : </label>
          <b-form-textarea
            id="input-wa_birthday_message"
            v-model="formSendMessage.wa_birthday_message"
            placeholder="Ucapan Selamat"
            rows="4"
            max-rows="6"></b-form-textarea>
          <small class="text-danger">{{
            errorSendMessage.wa_birthday_message
          }}</small>
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button variant="primary" type="submit" class="mr-1">
            Kirim
          </b-button>
          <b-button
            type="button"
            variant="secondary"
            @click="$bvModal.hide('modal-preview-message')">
            Batal
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { debounce } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
import validation from "@/core/modules/ValidationModule.js";

export default {
  components: {
    Card,
  },

  data() {
    return {
      filter: {
        name: "",
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 1,
      items: [],
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "display_birt_date",
          label: "Tgl. Ultah",
          sortable: true,
        },
        {
          key: "age",
          label: "Usia",
          sortable: true,
        },
        {
          key: "gender",
          label: "Jenis Kelamin",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        nextMonth: false,
        period: false,
      },
      // Other
      clinic_name: "Medhop",
      formWaSetting: {
        is_wa_auto_send: false,
        wa_birthday_message: "",
      },
      errorWaSetting: {
        is_wa_auto_send: "",
        wa_birthday_message: "",
      },
      formSendMessage: {
        wa_birthday_message: "",
        mobile_phone: "",
        patient_id: "",
      },
      errorSendMessage: {
        wa_birthday_message: "",
        mobile_phone: "",
        patient_id: "",
      },
      manipulateBtn: true,
      // type
      type: "days",
    };
  },

  methods: {
    pageOnClick(evt, page) {
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&type=${this.type}`;
      let response = await module.paginate(
        `patients-birthday`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    async settingWa() {
      this.formWaSetting = await module.get("settings");
      // If Data Not Found
      if (this.formWaSetting == null) {
        Swal.fire({
          title: "Gagal",
          text: "Data gagal dimuat",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        this.formWaSetting.is_wa_auto_send
          ? (this.formWaSetting.is_wa_auto_send = true)
          : (this.formWaSetting.is_wa_auto_send = false);
        this.formWaSetting["_method"] = "put";
        this.$bvModal.show("modal-wa-settings");
      }
    },

    async editWaSetting(evt) {
      evt.preventDefault();
      this.formWaSetting.is_wa_auto_send
        ? (this.formWaSetting.is_wa_auto_send = 1)
        : (this.formWaSetting.is_wa_auto_send = 0);
      let response = await module.submit(this.formWaSetting, "settings/1");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: "Berhasil",
          text: "Berhasil Menyimpan Pesan",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-wa-settings");
        // this.pagination()
      }
    },

    async showModalPreview(item) {
      let response = await module.get("settings");
      // If Data Not Found
      if (response == null) {
        Swal.fire({
          title: "Gagal",
          text: "Data gagal dimuat",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        this.formSendMessage.mobile_phone = item.mobile_phone
        this.formSendMessage.patient_id = item.id
        this.formSendMessage.wa_birthday_message = response.wa_birthday_message
        console.log('formsendmes', this.formSendMessage)
        this.$bvModal.show("modal-preview-message");
      }
    },

    async sendMessage(evt) {
      evt.preventDefault();
      let response = await module.submit(
        this.formSendMessage,
        "patients-send-birthday-message"
      );

      console.log(response, "res nihhh ");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: "Berhasil",
          text: "Berhasil kirim pesan",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-preview-message");
        this.pagination()
      }
    },

    filterByName() {
      debounce(() => { this.pagination() }, 500)
    },

    // filter
    resetFilter() {
      this.filter.name = "";
      this.filter.start_date = moment()
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.filter.end_date = moment()
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      this.pagination();
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      this.type = type

      if (type == "days") {
        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.nextMonth = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.nextMonth = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "nextMonth") {

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.nextMonth = true;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("patients-birthday/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },
    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        console.log("looping");
        if (access_right[a] == "3002") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    this.setActiveMenu();
    // set title 
    this.clinic_name = window.configuration == null ? "Medhop" : window.configuration.clinic_name
    this.pagination();
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien Ultah" },
      { title: "Daftar Pasien Ultah" },
    ]);
  },
};
</script>
